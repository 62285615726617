




import Vue from 'vue';

export default Vue.extend({
  auth: true,
  layout: 'layout-exterior',
  beforeMount() {
    this.$router.push('/SHS/IL');
  },
});
